<template>
  <!-- 审批流管理 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="采购订单号">
              <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同号">
              <el-input v-model="form.contractCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="STYLE">
              <el-select v-model="form.style" value-key="id" clearable filterable>
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" prop="vendorId">
              <el-select v-model="form.vendorId" clearable filterable :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="质检状态">
              <el-select v-model="form.qualityStatus" clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option label="待质检" value="0" />
                <el-option label="通过" value="1" />
                <el-option label="未通过" value="2" />

              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <ExportFile export-key="scm_quality" :params="form" />
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-row :span="24" class="mb-3">
      <!-- <el-button class="float-right" @click="addclick()">导出明细</el-button> -->
    </el-row>
    <el-table
      ref="multipleTable"
      :loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa'}"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column
        type="selection"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="purchaseOrderCode"
        label="采购订单号"
        width="170"
        sortable
      />
      <el-table-column
        align="center"
        prop="contractCode"
        label="合同号"
        sortable
      />
      <el-table-column
        prop="style"
        label="STYLE"
        align="center"
        sortable
      />
      <el-table-column
        prop="vendorName"
        label="供应商"
        align="center"
        sortable
      />
      <el-table-column
        prop="buyer"
        label="买家"
        align="center"
        sortable
      />
      <el-table-column
        prop="pairs"
        label="订单数量"
        align="center"
        sortable
      />
      <el-table-column
        prop="checkPairs"
        label="验货数量"
        align="center"
        sortable
      />
      <el-table-column
        prop="qualityStatus"
        label="质检状态"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <p v-if="scope.row.qualityStatus=='0'">待质检</p>
          <p v-if="scope.row.qualityStatus=='1'">通过</p>
          <p v-if="scope.row.qualityStatus=='2'">未通过</p>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('page.operate')"
        width="90"
        align="center"
      >
        <template slot-scope="scope">
          <router-link :to="{ path: '/quality-inspection/quality-mgmt/added', query: { id: scope.row.id,qualityStatus:scope.row.qualityStatus }}" style="color:#1890ff;">
            <el-button type="text" size="small">
              <p>查看</p>
            </el-button>
          </router-link>
        </template>
      </el-table-column>

    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        :page-sizes="[10, 50, 200, 500]"
        @pagination="_qualitypage"
      />
    </div>
  </div>
</template>
<script>
import { qualitypage, queryVendorList, queryStyleList } from '@/api/scm-api'
import Pagination from '@/components/Pagination'
import ExportFile from '@/components/ExportFile'

export default {
  components: { Pagination, ExportFile },
  data() {
    return {
      TableLoading: false,
      styleOptions: [],
      vendorOptions: [],
      showRow: false, // 切换显示input
      tableDatas: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {
      }
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      return Object.assign({}, this.pager, this.form)
    }
  },
  mounted() {
    this._queryStyleList()
    this._queryVendorList()
    this._qualitypage(this.queryParameter)
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index === 7 || index === 8) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              prev = (prev + '').indexOf('.') === -1 ? prev : Number(prev.toFixed(2))
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },

    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._qualitypage(this.queryParameter)
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _qualitypage(pagers) {
      try {
        this.TableLoading = true
        pagers && Object.assign(this.pager, pagers)
        const { datas } = await qualitypage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    }

  }
}
</script>
<style lang="scss">
.create-overseas .el-form-item__error {
  width: 86%;
  text-align: right;
}
</style>
